var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(("width : " + _vm.width))},[(_vm.label)?_c('div',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('h5',{staticStyle:{"color":"#4F5A6E","font-size":"13px","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.tooltip)?_c('ws-tooltip',{attrs:{"text":_vm.tooltip}},[_c('v-icon',{attrs:{"color":_vm.wsDARKLIGHT}},[_vm._v("mdi-information-outline")])],1):_vm._e()],1):_vm._e(),(!_vm.autocomplete)?_c('v-select',{staticClass:"textStyle mt-0",class:{
        activeText : _vm.focused && !_vm.error,
        notActive : !_vm.focused && !_vm.error,
        errorStyle : _vm.error,
        avalon : _vm.avalonStyle
      },attrs:{"items":_vm.items,"placeholder":!_vm.text || (_vm.text && _vm.text.length === 0)  ? _vm.placeholder : '',"color":_vm.wsACCENT,"type":_vm.type,"dense":"","outlined":!_vm.flat,"hide-details":_vm.hideDetails,"error":_vm.error,"error-messages":_vm.errorMessages,"height":_vm.height,"counter":_vm.counter,"clearable":_vm.clearable,"prepend-inner-icon":_vm.prependInnerIcon,"append-icon":_vm.appendIcon,"disabled":_vm.disabled,"multiple":_vm.multiple,"background-color":_vm.backgroundColor || null,"readonly":_vm.readonly,"flat":_vm.flat,"solo":_vm.solo,"no-data-text":_vm.$t('NoDataToSelect')},on:{"keypress":function($event){return _vm.handleKeyPress( $event,_vm.text )},"focusin":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false ; _vm.$emit('change',_vm.text)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"fill-height d-flex align-center",class:[{'justify-space-between' : item.right_icon}],staticStyle:{"width":"100%"}},[(item.icon)?_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.wsACCENT}},[_vm._v(" "+_vm._s(item.icon))]):_vm._e(),_c('h5',{style:(("color : " + _vm.wsACCENT))},[_vm._v(_vm._s(item.text))]),(item.right_icon)?_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.wsACCENT}},[_vm._v(" "+_vm._s(item.right_icon))]):_vm._e()],1)]}}],null,false,247412586),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_c('v-autocomplete',{ref:"autocomplete",staticClass:"mt-0",class:{
        activeText : _vm.focused && !_vm.error,
        notActive : !_vm.focused && !_vm.error,
        errorStyle : _vm.error,
        avalon : _vm.avalonStyle,
        tableStylePrimary : _vm.tableStylePrimary,
        textStyle : !_vm.tableStylePrimary
      },attrs:{"items":_vm.selectItems,"placeholder":_vm.placeholder,"color":_vm.wsACCENT,"type":_vm.type,"dense":"","outlined":!_vm.flat,"hide-details":!_vm.counter,"error":_vm.error,"height":_vm.height,"counter":_vm.counter,"clearable":_vm.clearable || (_vm.clearableOnHover && _vm.focused ),"multiple":_vm.multiple,"flat":_vm.flat,"solo":_vm.solo,"auto-select-first":"","background-color":_vm.backgroundColor || null,"append-icon":_vm.appendIcon},on:{"keypress":function($event){return _vm.handleKeyPress( $event,_vm.text )},"focusin":function($event){_vm.focused = true;},"blur":function($event){_vm.focused = false ; _vm.$emit('change',_vm.text)},"input":_vm.handleChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_vm._t("item",[(_vm.checkNewData(item))?_c('h5',{style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(item.value.includes('new-data::') ? _vm.$t(_vm.newDataText || 'Add') : '')+" \""+_vm._s(item.text)+"\" ")]):_c('h5',{style:(("color : " + _vm.wsACCENT))},[_vm._v(" "+_vm._s(item.text)+" ")])],{"item":item})]}},(_vm.allowNewData)?{key:"no-data",fn:function(){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"px-4 py-3 pointer",attrs:{"color":!hover ? 'transparent' : '#00000011'},on:{"click":_vm.handleNewData}},[_c('h5',{style:(("color : " + _vm.wsACCENT))},[_c('v-icon',{attrs:{"color":_vm.wsACCENT}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t(_vm.newDataText || 'Add'))+" \""+_vm._s(_vm.getSearchQuery())+"\" ")],1)])]}}],null,false,1554463155)})]},proxy:true}:null],null,true),model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),(_vm.avalonStyle)?_c('v-divider',{staticClass:"mt-n5",style:(("border-color : " + _vm.wsDARKLIGHT))}):_vm._e(),(_vm.description)?_c('h5',{staticClass:"font-weight-medium ",class:[{'mt-n4' : !_vm.autocomplete} , {'mt-2' : _vm.autocomplete} ],style:(("color: " + _vm.wsDARKLIGHT))},[_vm._v(_vm._s(_vm.$t(_vm.description)))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }